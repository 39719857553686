<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="my-card m-0">
                    <b-table 
                    striped
                    small
                    id="myTables"
                    bordered
                    selectable
                    selected-variant="primary"
                    select-mode="single"
                    responsive="sm"
                    :items="tariffAllowances"
                    :fields="fields"
                    head-row-variant="secondary"
                    >
                    <template #cell(checkbox)="row">
                        <b-form-checkbox
                        v-model="row.detailsShowing"
                        plain
                        class="vs-checkbox-con"
                        @change="row.toggleDetails"
                        >
                        </b-form-checkbox>
                    </template>
                    <template #cell(icon)="data">
                        <img v-if="data.item.icon" style="width: 30px; height: 30px;" :src="data.item.icon" alt="">
                    </template>
                    <template #cell(performer_show)="data">
                        <div>
                            {{ data.item.performer_show === 1 ? 'Активен' : 'Не активен' }}
                        </div>
                    </template>
                    <template #cell(client_show)="data">
                        <div>
                            {{ data.item.client_show === 1 ? 'Активен' : 'Не активен' }}
                        </div>
                    </template>
                    <template #cell(site_show)="data">
                        <div>
                            {{ data.item.site_show === 1 ? 'Активен' : 'Не активен' }}
                        </div>
                    </template>
                    <template #cell(user_show)="data">
                        <div>
                            {{ data.item.user_show === 1 ? 'Активен' : 'Не активен' }}
                        </div>
                    </template>
                </b-table>
            </b-card>
        </b-col>
        </b-row>
    </div>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
export default {
    data(){
        
        return{
            tariff_type_id: this.$route.query.tariff_type_id,
            tariffAllowances: [],
            fields: [
            {key: 'checkbox', label: '', thStyle: {width: '30px'}},
            {key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'}},
            {key: 'name', label: 'Наименование', sortable: true, thStyle: {width: '150px'} },
            {key: 'price', label: 'Цена', sortable: true, thStyle: {width: '150px'} },
            {key: 'type', label: 'Тип', sortable: true, thStyle: {width: '150px'} },
            {key: 'icon', label: 'Иконка', sortable: true, thStyle: {width: '150px'} },
            {key: 'performer_show', label: 'Статус у водителя', sortable: true, thStyle: {width: '150px'} },
            {key: 'client_show', label: 'Статус у клиента', sortable: true, thStyle: {width: '150px'} },
            {key: 'site_show', label: 'Статус на сайте', sortable: true, thStyle: {width: '150px'} },
            {key: 'user_show', label: 'Статуc у пользователя', sortable: true, thStyle: {width: '150px'} },
        ],
        }
    },
    methods:{
    },
    mounted(){
        resizeable()
        this.$store.commit('pageData/setdataCount', null)
        this.$http
            .get(`tariff/types/${this.tariff_type_id}/allowances`)
            .then(res => {
                this.tariffAllowances = res.data
                this.$store.commit('pageData/setdataCount', this.tariffAllowances.length)
            })
            .catch(err => {
                console.log(err);
            })
    }
}
</script> 